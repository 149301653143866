@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');


*{
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #1c1d3e !important;
  letter-spacing: 1px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 100vw;
  max-height: 100vh;
  -webkit-font-smoothing:antialiased;
}

img{
  max-width: 100%;
}


  /*---- Add New Css ------- */

  section{
    width: 100%;
    padding: 40px 0px;
  }

  h1, h2, h3{
    color: #1c1d3e;
  }

  p{
    color: #363636;
    font-size: 1rem;
    line-height: 23px;
    font-weight: 400;;
  }

  li{
  color: #363636;
  font-size: 0.875rem;
  line-height: 23px;
  font-weight: 400;;
  }

  .header-bottom-wrap.bg-theme-default{
    background-color: transparent;
  }

  .navigation-menu--text_white > ul > li > a:hover span{
    color: #007bff !important;
  }

  .header-area .is-sticky .navigation-menu--text_white > ul > li > a{
    color: rgb(41 41 41);
  }


  .header__logo.top-logo .logo1{
    display: none;
  }
  
  .header-area .is-sticky .header__logo.top-logo {
  padding: 5px 0;
}

  .header-area .is-sticky .header__logo.top-logo .logo1{
  display: block;
}

.header-area .is-sticky .header__logo.top-logo .logo2{
  display: none;
}

      .container{
        max-width: 1320px !important;
      }

      img{
        max-width: 100%;
      }

      @media screen and (max-width: 1600px){
        .container{
          max-width: 1220px !important;
        }
    }

      @media screen and (max-width: 1450px){
            .container{
              max-width: 1200px !important;
            }
        }

        @media screen and (max-width: 1400px){
            .container{
              max-width: 1120px !important;
            }
        }

        .title-effect-2 {
          width: 42px;
          height: 42px;
          position: relative;
          margin-bottom: 15px;
          -webkit-animation: inherit;
          animation: inherit;
          opacity: 1;
    
      }

      .ellipse {
        width: 100%;
        height: 100%;
        background: #00C9FF;
        border-radius: 50%;
        -webkit-animation: ellipse-animation 2.4s cubic-bezier(0, -0.26, 0.32, 1.22) 0s infinite;
        animation: ellipse-animation 2.4s cubic-bezier(0, -0.26, 0.32, 1.22) 0s infinite;
        -webkit-transform: rotate(
        0deg
        );
            transform: rotate(
        0deg
        );
    }

    .title-effect-2  span {
        position: absolute;
        top: 18%;
        left: 31%;
        color: #fff;
        font-size: 16px;
    }
    
      @keyframes ellipse-animation{
        0% {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        12.5% {
            border-top-left-radius: 0;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            transform: rotate(
        45deg
        );
        }
        25% {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            transform: rotate(
        90deg
        );
        }
        37.5% {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 50%;
            transform: rotate(
        135deg
        );
        }
        50% {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            transform: rotate(
        180deg
        );
        }
        62.5% {
            border-top-left-radius: 50%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            transform: rotate(
        225deg
        );
        }
        75% {
          border-top-left-radius: 50%;
          border-top-right-radius: 50%;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          transform: rotate(
      270deg
      );
      }
      87.5% {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 0;
            transform: rotate(
        315deg
        );
        }
        100% {
          border-top-left-radius: 50%;
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 50%;
          transform: rotate(
      360deg
      );
      }
      }


    .Services-box:hover::before {
        -webkit-transform: scale(1);
        transform: scale(1);
       
    }

    .Services-box::before {
     
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: -1;
        transition: all 0.5s ease-in-out 0s;
    }

    .Services-box:hover:after {
        opacity: 0.2;
    }

    .Services-box:hover h4{
      color: #fff;
    }

    .Services-box:hover p{
      color: #fff;
    }

    .footer-widget__list.address li a{
      color: #00C9FF !important;
      font-weight: 600;
    }


    /*---- Portfolio -----*/

    .projects-wrapper .messonry-button{
      width: 100%;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }

    .projects-wrap.style-01 .content{
      padding: 25px 26px 20px;
      width: calc(100% - 50px);
      margin-left: 26px;
      margin-top: -40px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
      z-index: 5;
      position: relative;
      background-color: #fff;
      border-radius: 10px;
    }

.messonry-button .is-checked{
  background: linear-gradient(90.04deg, #07CAFE 1.2%, #65DCFC 99.97%);
  color: #fff;
}

.projects-wrapper .section-title-wrap h3{
  font-size: 2rem;
  color: #101729;
  font-weight: 700;
  font-family: 'Ubuntu';
  line-height: 36px;
}

.content .viewbtn{
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-top: 10px;
}

.row.footer-widget-wrapper a:hover{
   color: #007bff;
}

.row.mesonry-list .cat--2:last-child{
  display: none;
} 

.clients-img{
  box-sizing:border-box;
  position:relative;
  border-radius: 50%;
}

.clients-img:before {
  animation:ripple 2s ease-out infinite;
  border:solid 8px #00C9FF;
  border-radius:50%;
  bottom:0;
  box-sizing:border-box;
  content:"";
  left:0;
  position:absolute;
  right:0;
  top:0;
}

.dropdown-toggle::after{
  position: relative;
  top: 3px;
}

/* .nav-link.active, .nav-link:active{
   color: #007bff;
} */

.dropdown-item.active, .dropdown-item:active{
  background-color: #fff;
  color: #292929;
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}

.Testimonials .react-multi-carousel-dot button{
  transition: all 0.3s ease-in-out;
}

.Testimonials .react-multi-carousel-dot.react-multi-carousel-dot--active button{
   transform: scale(1.1) !important;
   opacity: 0.8;
   animation: blink 2s linear infinite;
}

@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
  }



.connects-btn button:last-child{
  background: linear-gradient(
    90.04deg
    , rgb(16, 16, 16) 1.2%, rgb(0, 0, 0) 99.97%);
    color: #fff;
}

.connects-btn button:last-child:hover{
  background: linear-gradient(
90.04deg
,#07CAFE 1.2%,#65DCFC 99.97%);
}

.about-team:hover .team-description{
   padding: 30px 20px 6px;
}


  .clientsfeedback .react-multi-carousel-dot.react-multi-carousel-dot--active button:before{
        animation:ripple 2s ease-out infinite;
        border: solid 8px rgb(73 213 251 / 58%);
        border-radius:50%;
        bottom:0;
        box-sizing:border-box;
        content:"";
        left:0;
        position:absolute;
        right:0;
        top:0;
   }

  .clientsfeedback .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    transition: all 0.3s ease-in-out;
    border-radius:50%;
 }

 @keyframes ripple {
  from {
      opacity: 1;
      transform: scale3d(0.75,0.75,1);
  }
  
  to {
      opacity: 0;
      transform: scale3d(1.2,1.2,1);
  }
  }

.subscribe-card input:focus{
  outline: none;
}

.navbar-nav .nav-link:active{
    color: #2575fc !important;
}

.services-card-content {

  text-align: justify;
}

.services-card-content .svtitle {
  color: #0CCBFE;
  font-family: Nunito,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
}


.navbar .navbar-nav .nav-item .nav-link:active.active {
  color: #2575fc !important;
}

.nav-link:active .active{
  color: #2575fc !important;
}

.navbar .dropdown-item.active, .dropdown-item:active{
  background-color: #fff;
}

.footer-mobile-bg{
  display: none;
}


.virtualframe iframe{
  border: none !important;
  width: 100%;
  height: 100vh;
  overflow:hidden;
}

.threed-visual {
  margin-top: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: black;
  border-width: 1px;
  border-style: solid;
}
.three-d {
  margin-top: 120px;
text-align: center;
  margin-bottom: -50px;
}

.threed-visual iframe{
  border: none;
  width: 100%;
  height: 100vh;
}

.menu-mobile-view{
  display: none;
}


@media screen and (max-width: 860px) {
  .title-effect-2{
    margin: 10px auto 15px;
  }
}


@media screen and (max-width: 576px) {
  .projects-wrapper .messonry-button{
     flex-wrap: wrap;
  }

  .services-card-content {
    margin-top: -80px;
    margin-bottom: 40px;
    text-align: justify;
  }

  .bg-white .white-logo{
    display: block;
  }


  .navbar-toggler:focus{
    box-shadow: 0px 0px !important;
  }


  .footer-mobile-bg{
    display: block !important;
  }

  .menu-mobile-view{
    display: block;
  }

  .sub-dropdown .sub-dropdown-menu{
    display: none !important;
    z-index: 105 !important;
  }

  .sub-dropdown.toggle-dropdown .sub-dropdown-menu{
      display: block !important;
  }

  .svtitle{
    font-size: 20px !important;
    line-height: 26px !important;
    margin-bottom: 8px !important;
  }

  .navbar-toggler {
    border-radius: 4px !important;
    padding: 3px 8px !important;
}

  
}