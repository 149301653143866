.main {
  margin: auto;
  width: auto;
  border: 3px solid green;
  padding: 10px;
  background-color: #fffdf6;
}

.canvas {
  height: 400px;
  background-color: bisque;
}

.textHead {
  font-feature-settings: "pcap", "c2pc";
  font-variant-caps: all-petite-caps;
  font-family: cursive;
  position: relative;
  display: flex;
  font-size: 2em;
  font-weight: 300;
  line-height: 0.7em;
  letter-spacing: 2px;
  color: #00003f;
}

.picker {
  margin: auto;
  position: relative;
  display: flex;
  width: 140px !important;
  height: 160px !important;
  border: 2px solid rgb(82, 2, 20);
  ;
}

.xr p {
  text-align: center;
  padding: 5px;
  color: white;
  font-weight: 500;
  font-size: 1em;
  background-color: rgb(82, 2, 20);
}